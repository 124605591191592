import { useParams } from 'react-router-dom';
import './CenterContainer.css';
import useWindow from './useWindow';
import { LikeOutlined } from '@ant-design/icons';
import { useMemo } from 'react';


export default function SubmittedForm() {
    const { height } = useWindow();
    const { type } = useParams();
    const submitMessage = useMemo(()=> {
        switch(type) {
            case 'CreateForm':
                return (
                    <div style={{textAlign: 'center'}}>
                        <div>
                            Thank you for onboarding to Flophouse Avaliability.
                        </div><div>
                            If you have questions contact flophousecomedy@gmail.com
                        </div>
                    </div>
                )
            default:
                return 'Thank you for submiting form. If you have questions contact flophousecomedy@gmail.com';
        }
        
    }, [type])
    return (
        <div className='Container' style={{height: height - 200}}>
            <div className='innerContainer'>
            <div className='faceIcon'>
                <LikeOutlined />
            </div>
            <div className='text'>
                {submitMessage}
            </div>
            </div>
        </div>
    );
}