import { useParams, useNavigate } from "react-router-dom";
import { useCallback, useState } from 'react';
import './CenterContainer.css';
import { Spin } from 'antd';
import useWindow from "./useWindow";
import { getFormSubmitAPI } from "./getFormSubmitAPI";

export default function useFormSubmission(formType) {
    const { id } = useParams();
    const navigate = useNavigate();
    const { height } = useWindow();
    const [sending, setSending] = useState(false);
    const submitForm = useCallback((payload) => {
        setSending(true);
        fetch(getFormSubmitAPI(formType), {
            method: "POST",
            body: JSON.stringify({
                id,
                payload
            })
        }).then(async resp => {
            resp.json().then(resp => {
                console.log(resp?.body);
                localStorage.removeItem(id);
                navigate(`/SubmittedForm/${formType}`, { replace: true });
                setSending(false);
            })            
        }).catch(error => {
            console.log(error);
            navigate('/FailedForm', { replace: true });
        });
    }, [id, navigate, formType]);
    return {
        sending,
        submitForm,
        spinner: (<div className='Container' style={{height: height - 40}}>
        <div className='innerContainer'>
            <Spin size="large" />
        </div>
    </div>)
    }
}