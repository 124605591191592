export async function getOpenMicUsers(micName) {
    const allNames = [];
    let lastToken = undefined;
    do {
        const resp= await fetch('https://tj6z6385eg.execute-api.us-east-2.amazonaws.com/default/getOpenMicUsers',  {
            method: "POST",
            body: JSON.stringify({
                micName,
                lastToken
            })
        });
        const result = await resp.json();
        const { names, token } = JSON.parse(result.body);
        names.forEach(name => allNames.push(name));
        lastToken = token;
    } while(lastToken);
    return allNames;
}