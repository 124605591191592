import './CenterContainer.css';
import useWindow from './useWindow';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from 'react';
import { Spin } from 'antd';

export default function FormLoader() {
    const { height } = useWindow();
    const navigate = useNavigate();
    const { id } = useParams()
    useEffect(() => {
        const localStorageItem = localStorage.getItem(id);
        if(localStorageItem !== null) {
            const { type } = JSON.parse(localStorageItem);
            navigate(`/${type}/${id}`, { replace: true });
        }
        fetch(`https://7v60fm8er0.execute-api.us-east-2.amazonaws.com/default/getFormInfo`, {
            method: "POST",
            body: JSON.stringify({
                id
            })
        }).then(async resp => {
            resp.json().then(result => {
                const data = JSON.parse(result.body);
                const { type } = data;
                localStorage.setItem(id, JSON.stringify(data));
                navigate(`/${type}/${id}`, { replace: true });
            })            
        }).catch(error => {
            console.log(error);
            navigate('/FailedToLoad', { replace: true });
        });
    }, [id, navigate])
    return (
        <div className='Container' style={{height: height - 40}}>
            <div className='innerContainer'>
                <Spin size="large" />
            </div>
        </div>
    );
}