export async function getUploadURL(folder, key, file) {
    try {
        const resp = await fetch(`https://fxomw5gq3f.execute-api.us-east-2.amazonaws.com/default/producedShowFileUpload`,  {
            method: "POST",
            body: JSON.stringify({
                key,
                fileType: file.type,
                folder
            })
        });
        const trueResp = await resp.json();
        const { url } = JSON.parse(trueResp.body);
        return url;
    } catch (e) {
        console.log(e);
        return undefined;
    }
}