import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import './CenterContainer.css';
import { Spin } from 'antd';
import useWindow from "./useWindow";
import { openMics } from './openMics';
import { useMemo } from "react";

export default function useOpenMicFormSubmission(name, setMicNames) {
    const navigate = useNavigate();
    const { height } = useWindow();
    const [sending, setSending] = useState(false);
    const [newUser, setNewUser] = useState(null);
    const micItem = useMemo(() => {
        const [day, time, amPm] = name.split('-');
        const micItem = openMics.find(({dayOfWeek, startTime}) => dayOfWeek === day && startTime === `${time} ${amPm}`);
        return micItem;
    }, [name]);
    useEffect(() => {
        if(!micItem) navigate(`/MissingForm/${name}`, { replace: true });
    }, [micItem, name, navigate]);
    const submitForm = useCallback((payload) => {
        setSending(true);
        fetch(`https://1krsa271l8.execute-api.us-east-2.amazonaws.com/default/submitOpenMic`, {
            method: "POST",
            body: JSON.stringify({
                name,
                payload
            })
        }).then(async resp => {
            resp.json().then(result => {
                const resp = JSON.parse(result.body);
                if(resp === "NewUser") {
                    setNewUser(payload.email);
                } else if(resp === 'Submitted') {
                    setNewUser(null);
                    setMicNames(undefined);
                }
                setSending(false);
            })            
        }).catch(error => {
            console.log(error);
            navigate('/FailedForm', { replace: true });
        });
    }, [name, navigate, setMicNames]);
    return {
        sending,
        micItem,
        submitForm,
        newUser,
        spinner: (
            <div className='Container' style={{height: height - 40}}>
                <div className='innerContainer'>
                    <Spin size="large" />
                </div>
            </div>
        )
    }
}