import './EventForm.css';
import { Table, Switch, Button, Space } from 'antd';
import { useParams } from "react-router-dom";
import { useMemo, useState, useCallback } from 'react';
import { toDateString } from "./toDateString";
import useFetchNavigate from './useFetchNavigate';
import useFormSubmission from './useFormSubmission';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  { 
    title: 'Spot Type',
    dataIndex: 'option',
    key: 'option'
  }
];

export default function ConfirmForm() {
  const { id } = useParams();
  useFetchNavigate();
  const {
    sending,
    submitForm,
    spinner
  } = useFormSubmission('ConfirmForm');
  const events  = useMemo(() => {
    const localItem = localStorage.getItem(id);
    if(localItem) {
      const { payload: rawEvents } = JSON.parse(localItem);
      return rawEvents.map(({title, startTime, endTime, option}, index) => ({
        key: index,
        date: toDateString(startTime, endTime),
        name: title,
        start: new Date(typeof startTime === 'string' ? parseInt(startTime, 10) : startTime),
        end: new Date(typeof endTime === 'string' ? parseInt(endTime, 10) : endTime),
        option: option === 'spot' ? 'Spot' : 'Host'
      }))
    }
    return [];  
  }, [id]);
 
  const [capacity, setCapacity] = useState(new Set());
  const changeCapacity = useCallback((key) => {
    if(capacity.has(key)) {
      capacity.delete(key);
    } else {
      capacity.add(key);
    }
    setCapacity(new Set(capacity));
  }, [capacity]);
  const allColumns = useMemo(() => [...columns, 
    {
      title: 'Availiable',
      dataIndex: 'avaliable',
      key: 'avaliable',
      render: (_, record) => <Switch checked={capacity.has(`${record.key}`)} onChange={() => changeCapacity(`${record.key}`)}/>
    },
  ], [capacity, changeCapacity]);
  const Submit = useCallback(() => {
    const submitEvent = Array.from(capacity.values()).map(rawIndex => {
      const index = parseInt(rawIndex, 10);
      const { start } = events[index];
      return start.getTime();
    });
    submitForm(submitEvent);
  }, [capacity, events, submitForm]);
  if(sending) {
    return spinner;
  }
  return (
    <div className="Contianer">
      <h2>
        Flophouse Avaliability Confirmations
      </h2>
        <Space
          direction="vertical"
          size="large"
        >
          <Table columns={allColumns} dataSource={events} pagination={false}/>
          <Button type="primary" style={{float: 'right'}} onClick={Submit}> Submit </Button>
          <br/>
      </Space>
    </div>
  );
}
