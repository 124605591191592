import './EventForm.css';
import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Drawer, List, Spin } from 'antd';
import useOpenMicFormSubmission from './useOpenMicFormSubmission';
import { getOpenMicUsers } from './getOpenMicUsers';

export default function OpenMicForm() {
    const { name } = useParams();
    const [micNames, setMicNames] = useState(undefined);
    const {
        sending,
        submitForm,
        spinner,
        newUser,
        micItem
    } = useOpenMicFormSubmission(name, setMicNames);
    const { name:micName, startTime, endTime, dayOfWeek } = micItem ?? {};
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };
    const showDrawer = () => {
        setOpen(true);
    };
    const isNewUser = useMemo(() => newUser !== null, [newUser]);
    useEffect(() => {
        const findUsers = async () => {
            const resp = await getOpenMicUsers(name);
            resp.sort((a, b) => a.signupTime > b.signupTime)
            setMicNames(resp.map(({name}) => name));
        }
        if(!micNames && open) {
            findUsers();
        }
    }, [name, micNames, open]);
    if (sending) {
        return spinner;
    }
    return (<>
        <div className="Contianer">
            <h2>
                { micName } sign up
            </h2>
            <h4 style={{'textAlign': 'center'}}>
            {dayOfWeek} {startTime} - {endTime} 
            </h4>
            <Form
                name="basic"
                labelCol={{
                span: 9,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={submitForm}
                autoComplete="off"
            >
                <Form.Item
                    label="Email:"
                    name="email"
                    initialValue={isNewUser ? newUser : ""}
                    rules={[
                        {
                            required: true,
                            message: 'Please add your email',
                        },
                    ]}
                >
                    <Input disabled={isNewUser}/>
                </Form.Item>
                {
                    isNewUser && (
                        <Form.Item
                            label="Name:"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please add your name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        
                    ) 
                }
                {
                    isNewUser && (
                        <Form.Item
                            label="Number:"
                            name="number"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please add your phone number',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        
                    ) 
                }
                <Button type="primary" htmlType="submit" style={{float: 'right'}}>
                    Submit
                </Button>
            </Form>
        </div>
        <div className="Contianer" style={{'paddingTop': '40px'}}>
            <Button onClick={showDrawer}>
                View Signup
            </Button>
            
        </div>
        <Drawer
            title="Signups"
            placement="right"
            closable={false}
            onClose={onClose}
            open={open}
            getContainer={false}
        >
            { micNames ? <List
                size="small"
                dataSource={micNames}
                renderItem={(item) => <List.Item>{item}</List.Item>}
            /> : 
                <Spin size="large" />
            }
        </Drawer>
    </>);
}