import { useLayoutEffect, useState } from 'react';

export default function useWindow() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useLayoutEffect(() => {
    function updateSize() {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { 
    height,
    width
  };
}