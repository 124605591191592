import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from 'react';

export default function useOpenMicFormSubmission() {
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if(localStorage.getItem(id) === null) {
            navigate(`/${id}`, { replace: true });
        }
    }, [id, navigate]);
}