import './EventForm.css';
import { Table, Checkbox, Button, Space } from 'antd';
import { useParams } from "react-router-dom";
import { useMemo, useState, useCallback } from 'react';
import { toDateString } from "./toDateString";
import useFetchNavigate from './useFetchNavigate';
import useFormSubmission from './useFormSubmission';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
];

export default function EventForm() {
  const { id } = useParams();
  useFetchNavigate();
  const {
    sending,
    submitForm,
    spinner
  } = useFormSubmission('EventForm');
  const events  = useMemo(() => {
    const localItem = localStorage.getItem(id);
    if(localItem) {
      const { payload: rawEvents } = JSON.parse(localItem);
      return rawEvents.map(({title, startTime, endTime}, index) => ({
        key: index,
        date: toDateString(startTime, endTime),
        name: title,
        start: new Date(typeof startTime === 'string' ? parseInt(startTime, 10) : startTime),
        end: new Date(typeof endTime === 'string' ? parseInt(endTime, 10) : endTime),
      }))
    }
    return [];  
  }, [id]);
 
  const [capacity, setCapacity] = useState(new Set());
  const changeCapacity = useCallback((key) => {
    if(capacity.has(key)) {
      capacity.delete(key);
    } else {
      capacity.add(key);
    }
    setCapacity(new Set(capacity));
  }, [capacity]);
  const allColumns = useMemo(() => [...columns, 
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host',
      render: (_, record) => <Checkbox checked={capacity.has(`host-${record.key}`)} onChange={() => changeCapacity(`host-${record.key}`)}/>
    },
    {
      title: 'Spot',
      dataIndex: 'spot',
      key: 'spot',
      render: (_, record) => <Checkbox checked={capacity.has(`spot-${record.key}`)} onChange={() => changeCapacity(`spot-${record.key}`)}/>
    },
  ], [capacity, changeCapacity]);
  const Submit = useCallback(() => {
    const submitEvent = new Map();
    capacity.forEach(item => {
      const [type, rawIndex] = item.split('-');
      const index = parseInt(rawIndex, 10);
      const { start } = events[index];
      const startTime = start.getTime();
      if(submitEvent.has(startTime)) {
        const current = submitEvent.get(startTime);
        current.capacity.push(type);
        submitEvent.set(startTime, current);
      } else {
        submitEvent.set(startTime, {
          start: startTime,
          capacity: [ type ]
        });
      }
    });
    submitForm(Array.from(submitEvent.values()));
  }, [capacity, events, submitForm]);
  if(sending) {
    return spinner;
  }
  return (
    <div className="Contianer">
      <h2>
        Flophouse Avaliability
      </h2>
        <Space
          direction="vertical"
          size="large"
        >
          <Table columns={allColumns} dataSource={events} pagination={false}/>
          <Button type="primary" style={{float: 'right'}} onClick={Submit}> Submit </Button>
          <br/>
      </Space>
    </div>
  );
}
