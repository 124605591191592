function getHourMin (date) {
    const hour = date.getHours();
    const min = `${date.getMinutes()}`;
    const printableHour = hour % 12;
    return `${printableHour === 0 ? 12 : printableHour}:${min.length === 1 ? `${min}0` : min}${hour > 12 ? "pm" : "am"}`
}

export function toDateString(start, end) {
    const startDate = new Date(typeof start === 'string' ? parseInt(start, 10) : start);
    const endDate = new Date(typeof end === 'string' ? parseInt(end, 10) : end);
    return `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()} ${getHourMin(startDate)} - ${getHourMin(endDate)}`
}