import { useEffect, useState } from 'react';

export default function useEvents() {
    const [eventData, setEventData] = useState(null)
    useEffect(() => {
        fetch("https://xz5u5rjvcf.execute-api.us-east-2.amazonaws.com/default/pullEvents", {
            method: "POST"
        }).then(resp => {
            resp.json().then(data => {
                const disabledHours = {};
                JSON.parse(data.body).forEach(({ startTime, endTime }) => {
                    const startDate = new Date(startTime);
                    const endDate = new Date(endTime);
                    while(startDate.getTime() <= endDate.getTime()) {
                        const mapKey = `${startDate.getMonth()}-${startDate.getDate()}-${startDate.getHours()}`;
                        const currentMin = startDate.getMinutes();
                        if(!disabledHours[mapKey]) disabledHours[mapKey] = [];
                        disabledHours[mapKey].push(currentMin);
                        startDate.setMinutes(currentMin + 1);
                    }
                });
                setEventData(disabledHours);
            })
        })
    }, []);
    return {
        eventData
    }
}