import './CenterContainer.css';
import useWindow from './useWindow';
import { DislikeOutlined } from '@ant-design/icons';


export default function FailedToLoad() {
    const { height } = useWindow();
   const submitMessage = 'Form failed to completely load. Please contact flophousecomedy@gmail.com';
    return (
        <div className='Container' style={{height: height - 200}}>
            <div className='innerContainer'>
            <div className='faceIcon'>
                <DislikeOutlined />
            </div>
            <div className='text'>
                {submitMessage}
            </div>
            </div>
        </div>
    );
}