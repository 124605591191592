import './EventForm.css';
import { Button, Form } from 'antd';
import useFetchNavigate from './useFetchNavigate';
import useFormSubmission from './useFormSubmission';
import { useParams } from "react-router-dom";
import { useMemo } from 'react';

export default function CancelOpenMic() {
  const { id } = useParams();
  useFetchNavigate();
  const {
    sending,
    submitForm,
    spinner
  } = useFormSubmission('CancelOpenMic');
  const timeStr = useMemo(() => {
    const localItem = localStorage.getItem(id);
    if(localItem) {
      const { payload } = JSON.parse(localItem);
      const { startDOW, startKey } = payload;
      const [ startDate, startTime, ampm ] = startKey.split('-');
      return `${startDOW}, ${startDate} at ${startTime}${ampm}`
    }
    return ;  
  }, [id]);
  if (sending) {
    return spinner;
  }
  return (
    <div className="Contianer">
        <h2>
            Cancel Open Mic Spot
        </h2>
        <div style={{
            width: "fit-content",
            marginRight: "auto",
            marginLeft: "auto"
        }}>
            { timeStr }
        </div>
        <Form
            name="basic"
            labelCol={{
            span: 9,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
                paddingTop: "10px"
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={submitForm}
            autoComplete="off"
        >
            <Button type="primary" danger htmlType="submit" style={{float: 'right'}}>
                Cancel
            </Button>
        </Form>
    </div>
  );
}
