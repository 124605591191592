import './CenterContainer.css';
import useWindow from './useWindow';
import { useParams } from "react-router-dom";
import { MehFilled } from '@ant-design/icons';


export default function MissingForm() {
    const { height } = useWindow();
    const { id } = useParams()
    return (
        <div className='Container' style={{height: height - 200}}>
            <div className='innerContainer'>
            <div className='faceIcon'>
                <MehFilled />
            </div>
            {id && `Missing form ${id}`}
            </div>
        </div>
    );
}